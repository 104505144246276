<template>
  <section class="report-preview-wrapper">
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="reportData === undefined">
      <h4 class="alert-heading">Error fetching report data</h4>
      <div class="alert-body">
        No report found with this report id. Check
        <b-link class="alert-link" :to="{ name: 'apps-report-list' }"> Report List </b-link>
        for other reports.
      </div>
    </b-alert>

    <b-row v-if="reportData" class="report-preview">
      <!-- Col: Left (Report Container) -->
      <b-col cols="12" xl="9" md="8">
        <b-card no-body class="report-preview-card">
          <div class="m-1">
            <h3>Report Preview</h3>
            <hr />
          </div>

          <!-- FIXME: Temporary solution -->
          <iframe
            v-if="reportData.id"
            style="width: 880px; height: 100%; min-height: 800px; border: 0; display: block; margin: 0 auto"
            :src="`${baseUrl}/reports/${reportData.id}/view`"
            frameborder="0"
          ></iframe>
        </b-card>
      </b-col>

      <!-- Right Col: Card -->

      <b-col cols="12" md="4" xl="3" class="report-actions">
        <b-card>
          <div>
            <h3>
              <span class="text-muted">#{{ reportData.studentId }}</span> {{ reportData.name }}
            </h3>

            <table class="w-100">
              <tr>
                <th class="pb-50">
                  <span class="font-weight-bold">Program</span>
                </th>
                <td class="pb-50">
                  {{ reportData.programName }}
                </td>
              </tr>
              <tr>
                <th class="pb-50">
                  <span class="font-weight-bold">Status</span>
                </th>
                <td class="pb-50">
                  {{ reportData.status }}
                </td>
              </tr>
              <tr>
                <th class="pb-50">
                  <span class="font-weight-bold">Unpaid Balance</span>
                </th>
                <td class="pb-50">
                  {{
                    (reportData.paymentPlan.balance || 0).toLocaleString('en-US', {
                      style: 'currency',
                      currency: 'usd',
                    })
                  }}
                </td>
              </tr>
            </table>

            <hr />
          </div>

          <!-- Button: Send Report -->
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            v-b-toggle.sidebar-send-report
            variant="primary"
            class="mb-75"
            block
          >
            Send Report
          </b-button>

          <!-- Button: DOwnload -->
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            class="mb-75"
            block
            @click="initiateDownload"
          >
            Download
          </b-button>

          <!-- Button: Edit -->
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            class="mb-75"
            block
            :to="{ name: 'apps-report-edit', params: { id: $route.params.id } }"
          >
            Edit
          </b-button>
        </b-card>
      </b-col>
    </b-row>

    <report-sidebar-send-report v-if="reportData.id" :report-data="reportData" />
    <report-sidebar-add-payment />
  </section>
</template>

<script>
import { ref, onUnmounted } from '@vue/composition-api';
import store from '@/store';
import router from '@/router';
import { BRow, BCol, BCard, BCardBody, BTableLite, BCardText, BButton, BAlert, BLink, VBToggle } from 'bootstrap-vue';
import Logo from '@core/layouts/components/Logo.vue';
import Ripple from 'vue-ripple-directive';
import reportStoreModule from '../reportStoreModule';
import ReportSidebarSendReport from '../ReportSidebarSendReport.vue';
import ReportSidebarAddPayment from '../ReportSidebarAddPayment.vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent';

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTableLite,
    BCardText,
    BButton,
    BAlert,
    BLink,

    Logo,
    ReportSidebarAddPayment,
    ReportSidebarSendReport,
  },
  methods: {
    initiateDownload() {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: `Download initiated`,
          icon: 'DownloadIcon',
          variant: 'success',
          text: `Please wait one moment while we prepare your download...`,
        },
      });

      window.location.href = `${this.baseUrl}/reports/${this.reportData.id}/download`;
    },
  },
  data: function () {
    return {
      reportData: { paymentPlan: {} },
      paymentDetails: {},
      baseUrl: this.$http.defaults.baseURL,
    };
  },
  async created() {
    try {
      const response = await store.dispatch('app-report/fetchReport', { id: router.currentRoute.params.id });

      this.reportData = response.data;
      this.paymentDetails = {};
    } catch (error) {
      if (!error.response) {
        console.error(error);
        return;
      }

      if (error.response.status === 404) {
        this.reportData = error.response.data;
      }
    }
  },
  setup() {
    const REPORT_APP_STORE_MODULE_NAME = 'app-report';

    // Register module
    if (!store.hasModule(REPORT_APP_STORE_MODULE_NAME))
      store.registerModule(REPORT_APP_STORE_MODULE_NAME, reportStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(REPORT_APP_STORE_MODULE_NAME)) store.unregisterModule(REPORT_APP_STORE_MODULE_NAME);
    });

    return {};
  },
};
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/pages/app-report.scss';
</style>

<style lang="scss">
@media print {
  // Global Styles
  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Report Specific Styles
  .report-preview-wrapper {
    .row.report-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .report-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .report-actions {
      display: none;
    }
  }
}
</style>
