<template>
  <b-sidebar id="sidebar-send-report" sidebar-class="sidebar-lg" bg-variant="white" shadow backdrop no-header right>
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">Send Report</h5>

        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
      </div>

      <!-- Body -->
      <b-form class="p-2" @submit.prevent>
        <!-- To -->
        <b-form-group label="To" label-for="to">
          <b-form-input id="to" v-model="sendReportData.to" trim type="email" />
        </b-form-group>

        <!-- Subject -->
        <b-form-group label="Subject" label-for="subject">
          <b-form-input id="subject" v-model="sendReportData.subject" trim />
        </b-form-group>

        <!-- Message -->
        <b-form-group label="Message" label-for="message">
          <b-form-textarea id="message" v-model="sendReportData.message" rows="12" trim />
        </b-form-group>

        <!-- ? File/Attachement -->
        <b-badge variant="light-primary">
          <feather-icon icon="LinkIcon" />
          <span class="ml-50">Report Attached</span>
        </b-badge>

        <!-- Form Actions -->
        <div class="d-flex mt-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            type="submit"
            @click="send"
            :disabled="sendingEmail"
          >
            Send
          </b-button>
          <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="outline-secondary" @click="hide"> Cancel </b-button>
        </div>
      </b-form>
    </template>
  </b-sidebar>
</template>

<script>
import { BSidebar, BForm, BFormGroup, BFormInput, BFormTextarea, BButton, BBadge } from 'bootstrap-vue';
import { ref } from '@vue/composition-api';
import Ripple from 'vue-ripple-directive';
import ToastificationContent from '@core/components/toastification/ToastificationContent';

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
    BBadge,
  },
  directives: {
    Ripple,
  },
  props: {
    reportData: {
      type: Object,
      required: true,
    },
  },
  methods: {
    send() {
      this.sendingEmail = true;

      this.$http
        .post(`reports/${this.reportData.id}/send`, this.sendReportData)
        .then((response) => {
          this.sendingEmail = false;

          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `Email sent`,
              icon: 'EmailIcon',
              variant: 'success',
              text: `Your message with the attached document has successfully been sent.`,
            },
          });
        })
        .catch((e) => {
          this.sendingEmail = false;

          if (!e.response) {
            console.error(e);
            return;
          }

          console.log(e.response.data);
        });
    },
  },
  data: function () {
    let reportData = this.reportData;

    return {
      sendReportData: {
        to: 'reports@intellectualpoint.com',
        subject: `${reportData.name} - Financial Report`,
        message: `Hi there,\n\nHere is the Financial Report for Student #${reportData.studentId}. ${reportData.name} ${reportData.email} who pursuing the ${reportData.programName} certification.`,
        // ? Need to handle how you want to send item
        // * You can also send link to download report in message
      },
      sendingEmail: false,
    };
  },
};
</script>

<style></style>
